














































































import { WatchLoading } from "@/decorators/Loading";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { ProgramReportViewItem } from "@/types/Payment";
import { Header } from "@/types/Table";
import { Transaction } from "@sportango/backend";
import {
  getCorrectStatusAndMessage,
  getRightConvenienceFee,
  getRightPaymentMethod,
  parsePaymentDate
} from "@/utils/payments";
import Component, { mixins } from "vue-class-component";
import Fuse from "fuse.js";
import { AutoCompleteItem } from "@/components/Inputs/mixins";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import { SharedPaymentIntents, SharedPaymentMethods } from "@/mixins/Payments";
import {
  SportangoSelect,
  SportangoTextField,
  SportangoAutocomplete
} from "@/components/Inputs/overrides";
import ProgramsAutoComplete from "@/components/Inputs/ProgramsAutoComplete.vue";

@Component({
  name: "program-payments-report",
  components: {
    ProgramsAutoComplete,
    SportangoTextField,
    SportangoSelect,
    PaymentStatus,
    TablePaymentMethod,
    SportangoAutocomplete
  }
})
export default class ProgramPaymentsReport extends mixins(
  CurrentUserMixin,
  LoadingMixin,
  SharedPaymentIntents,
  SharedPaymentMethods
) {
  selectedPlayer = "";
  searchText = "";
  selectedProgram = "";

  get playerToFilter(): string | null {
    if (
      this.currentUser?.permissions.hasAdminAccess ||
      this.currentUser?.permissions.hasCoachAccess
    ) {
      if (this.selectedPlayer && this.selectedPlayer.length > 0) {
        return this.selectedPlayer;
      }
    } else if (this.currentUser?.permissions.hasPlayerAccess) {
      return this.currentUser.uid;
    }
    return null;
  }

  get headers(): Array<Header<ProgramReportViewItem>> {
    return [
      {
        value: "itemName",
        text: "Name"
      },
      {
        value: "customerName",
        text: "Player"
      },
      {
        value: "amount",
        text: "Amount",
        align: "center",
        sortable: true,
        width: "120px"
      },
      {
        value: "convenienceFee",
        text: "Fee",
        sortable: false,
        align: "center"
      },
      {
        value: "total",
        text: "Total",
        sortable: false,
        align: "center"
      },
      {
        value: "status",
        align: "center",
        sortable: false,
        text: "Status"
      },
      {
        value: "paymentMethod",
        text: "Payment Method",
        sortable: false,
        align: "center",
        width: "150px"
      },
      {
        value: "paymentDateShort",
        text: "Date",
        sortable: true,
        align: "center"
      }
    ];
  }

  get items(): Array<ProgramReportViewItem> {
    const returnValue: Array<ProgramReportViewItem> = [];
    this.transactions.forEach((t, transactionIndex) => {
      t.customers?.forEach((customerInfo, customerIndex) => {
        const customerPaymentIntent = this.paymentIntents.find(
          (p) => p !== null && p.id === customerInfo.paymentIntentId
        );
        const playerInfo = this.$store.getters.users.find(
          (u) => u.uid === customerInfo.uid
        );
        const { status, statusMessage } = getCorrectStatusAndMessage(
          customerInfo,
          customerPaymentIntent || undefined
        );
        const { short, full } = parsePaymentDate(
          customerPaymentIntent?.created,
          customerInfo
        );
        const result = {
          itemName: t.description || "Payment",
          itemLink: t.parentItem || "",
          amount: customerInfo.amount || 0,
          convenienceFee: getRightConvenienceFee(
            status,
            customerInfo.amount || 0,
            null,
            customerInfo,
            this.$store.getters.merchantInfo
          ),
          total: customerInfo.amount || 0,
          status,
          statusMessage,
          disabled: true,
          isPaymentRunning: !this.paymentIntentsLoaded,
          paymentMethod: customerPaymentIntent
            ? this.paymentMethods.find(
                (p) => p?.id === getRightPaymentMethod(customerPaymentIntent)
              ) || null
            : null,
          paymentDate: full,
          paymentDateShort: short,
          parent: t.parentItem || "",
          playerId: customerInfo.uid || "",
          customerName: playerInfo?.displayName || "",
          date:
            (customerInfo.paidInCash
              ? customerInfo.cashPaidDate
              : (customerPaymentIntent?.created || 0) * 1000) || 0,
          transactionId: t.id || "",
          uid: playerInfo?.uid || "",
          itemId: `${transactionIndex}-${customerIndex}`,
          paidInCash: customerInfo.paidInCash
        };
        result.total = result.amount + result.convenienceFee;
        returnValue.push(result);
      });
    });
    return returnValue;
  }

  get filteredItems(): Array<ProgramReportViewItem> {
    let items = this.items;
    if (this.selectedProgram) {
      items = items.filter((i) => i.parent === this.selectedProgram);
    }
    if (this.playerToFilter) {
      items = items.filter((i) => i.playerId === this.selectedPlayer);
    }
    if (this.searchText.trim().length >= 2) {
      const fuse = new Fuse<ProgramReportViewItem>(items, {
        keys: [
          "itemName",
          "customerName",
          "amount",
          "total",
          "paymentDateShort"
        ],
        threshold: 0.3,
        minMatchCharLength: 2
      });
      items = fuse.search(this.searchText).map((r) => r.item);
    }
    return items.sort((a, b) => b.date - a.date);
  }

  get programItems(): Array<AutoCompleteItem | undefined> {
    const programs = this.transactions.map((t) => t.parentItem);
    const matchedPrograms = [
      {
        value: "",
        text: "All Programs"
      },
      ...this.$store.getters.programs
        .filter((p) => programs.includes(p.id))
        .map((p) => {
          return {
            text: p.name || "",
            value: p.id || ""
          };
        })
    ];
    return matchedPrograms;
  }

  isPaymentMethodLoad(item?: ProgramReportViewItem) {
    if (item !== undefined) {
      if (item.paymentMethod === null) {
        if (this.paymentMethods.length === 0) {
          return false;
        }
        return true;
      }
    }
    return true;
  }

  get userItems(): Array<AutoCompleteItem | undefined> {
    let users: string[] = [];
    this.transactions
      .filter((t) => {
        if (this.selectedProgram.length > 0) {
          return t.parentItem === this.selectedProgram;
        }
        return true;
      })
      .map((t) => t.customers || [])
      .filter((u) => u.length > 0)
      .forEach((u) => {
        users = [...users, ...u.map((u) => u.uid || "")];
      });
    const matchedPlayers = [
      {
        text: "All Users",
        value: ""
      },
      ...this.$store.getters.users
        .filter((p) => users.includes(p.uid))
        .map((p) => {
          return {
            text: p.displayName || "",
            value: p.uid || ""
          };
        })
    ];
    return matchedPlayers;
  }

  get transactions(): Array<Transaction> {
    return this.$store.getters.transactions;
  }

  @WatchLoading()
  async mounted() {
    await this.$store.dispatch("getTransactions", "program");
    await this.getPaymentIntents(this.transactions);
    await this.getPaymentMethodsForIntents(this.paymentIntents);
  }
}
