























import { Program } from "@sportango/backend";
import { getFinalArray } from "@/utils/validation";
import { LoadingMixin } from "@/mixins/Helpers";
import { findPrograms, getPlayerIds } from "@/store/actions/programs";
import Component, { mixins } from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import {
  AutoCompleteItem,
  AutoCompleteMixin,
  TextFieldMixin
} from "../Inputs/mixins";
import MobileAutoComplete from "@/components/Navigation/MobileAutoComplete.vue";
import dayjs from "dayjs";

@Component({
  name: "programs-auto-complete",
  components: {
    MobileAutoComplete
  }
})
export default class ProgramsAutoComplete extends mixins(
  TextFieldMixin,
  AutoCompleteMixin,
  LoadingMixin
) {
  @VModel({ type: [String, Array] })
  field!: string | Array<string>;

  @Prop({ required: false, default: 5, type: Number })
  maxResults!: number;

  @Prop({ required: false })
  ignoreIds!: Array<string> | undefined;
  @Prop({ required: false })
  idsToConsider!: Array<string> | undefined;

  private availablePrograms: Array<AutoCompleteItem> = [];

  get usersToShow(): Array<AutoCompleteItem> {
    let usersToShow = this.availablePrograms;
    if (this.ignoreIds) {
      usersToShow = usersToShow.filter((u) => {
        if (this.disabled) {
          if (Array.isArray(this.field)) {
            return this.field.includes(u.value.toString());
          } else if (typeof this.field === "string") {
            return u.value === this.field;
          }
        }
        return !this.ignoreIds?.includes(u.value.toString());
      });
    }
    return usersToShow;
  }

  private async fetchUsers(searchText: string): Promise<void> {
    if (this.idsToConsider && this.idsToConsider.length > 0) {
      await this.$store.dispatch("getProgramById", this.idsToConsider);
      this.availablePrograms = [
        ...this.$store.getters.programs
          .filter((u) => this.idsToConsider?.includes(u.id || ""))
          .map((u) => {
            const autoCompleteItem: AutoCompleteItem = {
              value: u.id || "",
              text: `${u.name} - ${dayjs(u.startDate).format(
                "MM/DD/YYYY"
              )} - ${dayjs(u.endDate).format("MM/DD/YYYY")}`
            };
            return autoCompleteItem;
          })
      ];
    } else {
      const programs = await findPrograms(
        searchText,
        this.maxResults + (this.ignoreIds?.length || 0)
      );

      this.$store.commit(
        "programs",
        getFinalArray<Program>(this.$store.getters.programs, programs, "id")
      );

      await this.$store.dispatch("getUsersById", getPlayerIds(programs));
      this.availablePrograms = programs.map((u) => {
        const autoCompleteItem: AutoCompleteItem = {
          value: u.id || "",
          text: `${u.name} - ${dayjs(u.startDate).format(
            "MM/DD/YYYY"
          )} - ${dayjs(u.endDate).format("MM/DD/YYYY")}`
        };
        return autoCompleteItem;
      });
      if (this.field) {
        let existingPrograms = this.field;
        if (typeof this.field === "string") {
          existingPrograms = [this.field];
        }
        await this.$store.dispatch("getProgramById", existingPrograms);
        this.availablePrograms = [
          ...this.availablePrograms,
          ...this.$store.getters.programs
            .filter((u) => existingPrograms.includes(u.id || ""))
            .map((u) => {
              const autoCompleteItem: AutoCompleteItem = {
                value: u.id || "",
                text: `${u.name} - ${dayjs(u.startDate).format(
                  "MM/DD/YYYY"
                )} - ${dayjs(u.endDate).format("MM/DD/YYYY")}`
              };
              return autoCompleteItem;
            })
        ];
      }
    }
  }

  async mounted(): Promise<void> {
    await this.fetchUsers("");
  }
}
